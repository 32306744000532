.header-cont {
    position: relative;
    font-weight: bolder;
    font-size: 2.5vw;
    height: 17vw;
    line-height: 17vw;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: center;
    padding-right: 0px;
  }

  .nav-links{
    text-decoration: none;
    color: black;
  }

  .nav-links:hover{
    color: #59a2c5;
  }

  .saveg-logo {
    width: 18vw;
  }
  
  .hamburger{
    display: none;
  }

  .hamburger-active{
    display: none;
  }

  .nav-links2{
    display: none;
  }

  .nav-links2-active{
    display: none;
  }

  @media screen and (max-width: 1000px){
    .header-cont{
      padding-right: 0px;
      height: 35vw;
      line-height: 0;
    }
    .nav-links{
      display: none;
    }
    .saveg-logo{
      width: 35vw;
      position: absolute;
      left: 5vw;
    }
    .hamburger{
      position: fixed;
      display: block;
      z-index: 10000;
      right: 0;
      top: 0;
      height: 7vw;
      width: 7vw;
      padding: 3vw;
      padding-right: 4vw;
      border-bottom-left-radius: 2vw;
      background-color: #59a2c5;
      cursor: pointer;     
      transition: right 0.5s ease;
    }

    .hamburger-active{
      position: fixed;
      display: block;
      z-index: 10000;
      right: 49.5vw;
      top: 0;
      height: 7vw;
      width: 7vw;
      padding: 3vw;
      padding-right: 4vw;
      border-bottom-left-radius: 2vw;
      background-color: #59a2c5;
      cursor: pointer;
      transition: right 0.5s ease;
    }

    .nav-links2{
      display: block;
      text-decoration: none;
      font-size: 0vw;
      font-weight: 600;
      color: black;
      line-height: 15vh;
      transition: all 0.5s ease;
    }

    .nav-links2-active{
      display: block;
      text-decoration: none;
      font-size: 4vw;
      font-weight: 600;
      color: black;
      line-height: 15vh;
      transition: all 0.5s ease;
    }
  
    .nav-links2:hover{
      color: white;
    }

    .nav-links2-active:hover{
      color: white;
    }

    .hamburger-menu-cont{
      background-color: #59a2c5;
      position: fixed;
      height: auto;
      z-index: 10000;
      right: 0;
      top: 0;
      width: 0vw; 
      transition: all 0.5s ease;
      border-bottom-left-radius: 5vw;
    }

    .hamburger-menu-cont-active{
      background-color: #59a2c5;
      padding-bottom: 5vh;
      padding-top: 5vh;
      z-index: 10000;
      position: fixed;
      width: 50vw;
      height: auto;
      display: block;
      right: 0;
      top: 0;
      transition: all 0.5s ease;
      border-bottom-left-radius: 5vw;
    }
  }

  @media screen and (max-width: 600px){
    .nav-links2{
      font-size: 0px;
    }

    .nav-links2-active{
      font-size: 24px;
    }
  }

  @media screen and (min-width: 750px){
    .hamburger{
      height: 52.5px;
      width: 52.5px;
      padding: 22.5px;
      padding-right: 30px;
      border-bottom-left-radius: 15px;
    }

    .hamburger-active{
      right: 49.5vw;
      height: 52.5px;
      width: 52.5px;
      padding: 22.5px;
      padding-right: 30px;
      border-bottom-left-radius: 15px;
    }

  }