.episodes-intro-cont {
  margin: 0 auto;
  background-color: white;
  width: 80vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  border-radius: 5vw;
}

.num-episodes {
  background-color: #3b3838;
  color: white;
  padding: 2vw 0px;
  font-size: 3vw;
  width: 60vw;
  margin: 0 auto;
  transform: skew(-20deg);
}

.num-episodes div {
  transform: skew(20deg);
}

.episodes-par {
  padding: 5vw;
  font-size: 2vw;
  font-weight: 400;
  background-color: white;
  width: 60vw;
  margin: 0 auto;
  text-align: center;
}

.saveg-rating {
  background-color: #3b3838;
  color: rgb(255, 200, 0);
  padding: 2vw 0px;
  font-size: 3vw;
  width: 60vw;
  margin: 0 auto;
  height: 18vw;
  transform: skew(-20deg);
}

.saveg-rating-inner1 {
  transform: skew(20deg);
}

.spotify-rating {
  position: absolute;
  top: 0;
  left: 4.5vw;
  height: 7.5vw;
}

.saveg-rating-number {
  position: absolute;
  font-size: 4vw;
  font-weight: 600;
  top: 1.1vw;
  right: 15vw;
}

.saveg-rating-stars {
  position: absolute;
  top: 8.5vw;
  left: 3vw;
}

.saveg-rating-reviews {
  color: white;
  position: absolute;
  top: 9.3vw;
  right: 8vw;
}
.rating-star-icon {
  color: rgb(255, 200, 0);
  height: 3.5vw;
  margin-right: 1vw;
  margin-top: 1vw;
}

.search-bar {
  margin: 0 auto;
  margin-top: 5vw;
  background-color: white;
  text-align: center;
  font-weight: light;
  margin-bottom: 5vw;
  font-size: 3vw;
  width: 80vw;
  height: 7vw;
  border-radius: 5vw;
  border-style: none;
  z-index: 1;
}

input[type="search"]::-webkit-input-placeholder {
  color: #3b3838;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.episode-card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 5vh;
  margin-bottom: 5vw;
  height: 40vh;
  width: 80vw;
  margin: auto;
  margin-bottom: 6vw;
  text-align: left;
}

.episode-column {
  height: 75%;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.episode-image {
  height: 30vh;
  border-top-left-radius: 5vh;
}

.episode-text {
  text-align: center;
  height: 30vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.episode-title {
  font-size: 2.5vw;
  padding: 2vw;
  font-weight: bold;
}

.episode-desc {
  display: inline-block;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 1.5vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.episode-audio {
  display: block;
  margin: 0 auto;
  margin-top: 3vh;
  height: 10%;
  width: 80%;
}

.show-cont {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
}

.show-more {
  background-color: #3b3838;
  color: white;
  font-size: 2.5vw;
  margin-bottom: 10vw;
  margin-top: 3vw;
  width: 25vw;
  padding: 2vw;
  margin-right: 10vw;
  cursor: pointer;
  border-radius: 5vw;
}

.show-less {
  background-color: #3b3838;
  color: white;
  font-size: 2.5vw;
  margin-top: 3vw;
  margin-bottom: 10vw;
  width: 25vw;
  padding: 2vw;
  cursor: pointer;
  border-radius: 5vw;
}

@media screen and (min-width: 1200px) {
  .episodes-intro-cont {
    width: 960px;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 60px;
  }
  .num-episodes {
    padding: 24px 0px;
    font-size: 36px;
    width: 720px;
  }
  .episodes-par {
    padding: 60px;
    font-size: 24px;
    width: 720px;
  }

  .saveg-rating {
    padding: 24px 0px;
    font-size: 36px;
    width: 720px;
    height: 216px;
  }

  .spotify-rating {
    left: 54px;
    height: 90px;
  }

  .saveg-rating-number {
    font-size: 48px;
    top: 13.2px;
    right: 180px;
  }

  .saveg-rating-stars {
    top: 102px;
    left: 36px;
  }

  .saveg-rating-reviews {
    top: 111.6px;
    right: 96px;
  }
  .rating-star-icon {
    height: 42px;
    margin-right: 12px;
    margin-top: 12px;
  }
  .search-bar {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 36px;
    width: 960px;
    height: 84px;
    border-radius: 60px;
  }
  .episode-card {
    border-radius: 60px;
    margin-bottom: 60px;
    height: 40vh;
    width: 960px;
    margin-bottom: 72px;
  }
  
.episode-image {
    border-top-left-radius: 60px;
  }
  
  .episode-title {
    font-size: 30px;
    padding: 24px;
  }
  
  .episode-desc {
    font-size: 18px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .show-more {
    font-size: 30px;
    margin-top: 36px;
    margin-bottom: 120px;
    width: 300px;
    padding: 24px;
    margin-right: 120px;
    border-radius: 60px;
  }
  
  .show-less {
    font-size: 30px;
    margin-top: 36px;
    margin-bottom: 120px;
    width: 300px;
    padding: 24px;
    border-radius: 60px;
  }
}

@media screen and (min-width: 1500px){
    .episode-card-cont{
        display: grid;
        grid-template-columns: repeat(2,auto);
    }
    .episode-card{
        width: 45vw;
    }
}

@media screen and (max-width: 750px) {
  .episode-card {
    height: auto;
  }
  .episode-column {
    display: block;
  }
  .episode-image {
    height: auto;
    width: 100%;
    border-top-right-radius: 5vh;
  }
  .episode-text {
    height: 300px;
  }
  .episode-title {
    font-size: 4vw;
  }
  .episode-desc {
    font-size: 2vw;
  }
  .episode-audio {
    transform: translateY(-35%);
    width: 80%;
  }
  .episode-card audio {
    height: 75px;
  }
}

@media screen and (max-width: 750px) {
  .episodes-par {
    font-size: 15px;
    font-weight: 300;
  }

  .show-cont {
    display: grid;
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }

  .show-more {
    font-size: 18.75px;
    width: 300px;
    margin-right: 0px;
    margin-bottom: 3vw;
  }

  .show-less {
    font-size: 18.75px;
    width: 300px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 600px) {
  .episode-title {
    font-size: 24px;
  }
  .episode-desc {
    font-size: 12px;
  }
}

@media screen and (max-width: 533px) {
  .search-bar {
    font-size: 16px;
    height: 37.33px;
  }
}
