.city-cont{
    margin-right: 5vw;
    margin-left: 5vw;
}

.city-title{
    font-size: 3vw;
    font-weight: bold;
    font-style: italic;
    margin-top: 5vw;
}

.city-vegan{
    font-weight: bold;
    font-style: italic;
    font-size: 2.5vw;
    margin: 0 auto;
    margin-top: 3vw;
    line-height: 5vw;
    margin-bottom: 4vw;
}

.city-vegan-take{
    display: inline-block;
    word-wrap: break-word;
    white-space: pre-line;
    margin: 0 auto;
    margin-top: 1vw;
    font-weight: 100;
    font-size: 2.1333vw;
    line-height: 3vw;
    width: 80vw;
}

.photo-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: 1fr;
    column-gap: 3vw;
    row-gap: 3vw;
    margin-left: 0vw;
    margin-right: 0vw;
    margin-bottom: 6vw;
}

.photo-grid img{
    width: 100%;
    height: 30vw;
    object-fit: cover;
}

@media screen and (max-width: 750px){
    .city-title{
        font-size: 22.5px;
        margin-top: 37.5px;
    }
    
    .city-vegan{
        font-size: 18.75px;
        margin-top: 30px;
        line-height: 37.5px;
        margin-bottom: 37.5px;
    }

    .city-vegan-take{
        margin-top: 7.5px;
        font-weight: 100;
        font-size: 16px;
        line-height: 22.5px;
    }

    .photo-grid{
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 22.5px;
        row-gap: 22.5px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 45px;
    }

    .photo-grid img{
        height: 40vw;
    }
}