* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 70vw;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
  margin-bottom: 5vw;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
}

.slide-title {
  width: 70%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 6vw;
  text-align: center;
  padding: 2vw;
  z-index: 9;
}

.prev,
.next {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 1rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
  background-color: gray;
}

.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 9;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  border-radius: 50%;
  display: inline-block;
}

.dot-active-dot {
  cursor: pointer;
  height: 2vw;
  width: 2vw;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: inline-block;
}

.all-dots span:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

/*   additional    */

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 2vw;
  color: white;
  font-weight: bold;
  font-size: 3vw;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 2vw;
  width: 2vw;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --heights: 840px;
    --widths: 1110px;
  }
  .slide-title {
    width: 777px;
    font-size: 72px;
    padding: 24px;
  }
  .prev,
  .next {
    padding: 24px;
    font-size: 36px;
  }
  .dot {
    height: 24px;
    width: 24px;
  }
  .dot-active-dot {
    height: 24px;
    width: 24px;
  }
}

@media screen and (max-width: 750px) {
  .slider-container {
    margin-bottom: 37.5px;
  }
  .dot {
    height: 15px;
    width: 15px;
  }
  .dot-active-dot {
    height: 15px;
    width: 15px;
  }
  .prev,
  .next {
    font-size: 22.5px;
  }
}
