.contact-us-image {
  width: 100%;
}
.contact-form-cont {
  margin: 0 auto;
  margin-top: 5vw;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5vw;
  width: 83.33vw;
  background-color: #3b3838;
  color: white;
  padding-bottom: 3vw;
}

input:focus {
  outline: none;
}
input::placeholder {
  color: white;
}
textarea {
  outline: none;
}
textarea::placeholder {
  color: white;
}

.contact-form {
  display: grid;
  width: 80%;
  margin-left: 10%;
}

.contact-form-name {
  border-style: none;
  background-color: #3b3838;
  border-bottom-style: solid;
  border-radius: 0px;
  border-color: white;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  padding-left: 2vw;
  margin-top: 5vw;
}

.contact-form-email {
  border-style: none;
  background-color: #3b3838;
  border-bottom-style: solid;
  border-radius: 0px;
  border-color: white;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  padding-left: 2vw;
  margin-top: 3vw;
}
.contact-form-subject {
  border-style: none;
  background-color: #3b3838;
  border-bottom-style: solid;
  border-radius: 0px;
  border-color: white;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  padding-left: 2vw;
  margin-top: 3vw;
}
.contact-form-text {
  font-size: 2.5vw;
  background-color: #3b3838;
  border-color: white;
  color: white;
  padding: 2vw;
  font-weight: bold;
  margin-top: 3vw;
  resize: none;
}

.submit-button {
  margin-top: 3vw;
  background-color: white;
  border-radius: 3vw;
  border-style: none;
  font-size: 3vw;
  font-weight: bold;
  color: black;
  cursor: pointer;
  height: 7vw;
  width: 40%;
  margin-left: 30%;
}

.submit-button:hover {
  background-color: #59a2c5;
}

.submit-text-cont {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.submit-text {
  line-height: 7vw;
}

.submit-airplane {
  height: 6vw;
}

.contact-form-email-desc {
  margin-top: 3vw;
  font-size: 2vw;
  font-weight: 100;
  text-align: center;
  width: 80%;
  margin-left: 10%;
}

.contact-form-bottom-spacer {
  height: 8vw;
}

/*///////////////Toast pop up/////////////////////////
////////////////////////////////////////////////////*/

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 35vw; /* Set a default minimum width */
  background-color: white; /* Black background color */
  color: black; /* White text color */
  font-weight: bold;
  font-size: 2vw;
  text-align: center; /* Centered text */
  border-radius: 3vw; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 10000; /* Add a z-index if needed */
  top: 30px; /* 30px from the bottom */
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1200px){
  #snackbar {
    font-size: 24px;
  }
}
@media screen and (max-width: 800px) {
#snackbar{
  font-size: 16px;
}
}
/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}
/*////////////////////////////////////////////////
////////////////////////////////////////////////*/

@media screen and (min-width: 1200px){
    .contact-us-image {
        width: 1100px;
      }
      .contact-form-cont {
        width: 1000px;
        margin: 0 auto;
        margin-top: 60px;
        border-radius: 60px;
        padding-bottom: 36px;
      }
      .contact-form {
        width: 888px;
        margin: 0 auto;
      }
      
      .contact-form-name {
        border-radius: 0px;
        font-size: 36px;
        padding-left: 24px;
        margin-top: 60px;
      }
      
      .contact-form-email {
        border-radius: 0px;
        font-size: 36px;
        padding-left: 24px;
        margin-top: 36px;
      }
      .contact-form-subject {
        border-radius: 0px;
        font-size: 36px;
        padding-left: 24px;
        margin-top: 36px;
      }
      .contact-form-text {
        font-size: 30px;
        padding: 24px;
        margin-top: 36px;
      }
      
      .submit-button {
        margin-top: 36px;
        border-radius: 36px;
        font-size: 36px;
        height: 84px;
        width: 40%;
        margin-left: 30%;
      }
      
      .submit-text {
        line-height: 84px;
      }
      
      .submit-airplane {
        height: 72px;
      }
      
      .contact-form-email-desc {
        margin-top: 36px;
        font-size: 24px;
        width: 80%;
        margin-left: 10%;
      }

}

@media screen and (max-width: 800px) {
  .contact-form-email-desc {
    font-size: 16px;
    margin-top: 16px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 533px) {
  .submit-button {
    margin-top: 24px;
    font-size: 16px;
    width: 50%;
    margin-left: 25%;
  }
  .contact-form-name {
    font-size: 16px;
  }
  .contact-form-email {
    font-size: 16px;
    margin-top: 16px;
  }
  .contact-form-subject {
    font-size: 16px;
    margin-top: 16px;
  }
  .contact-form-text {
    font-size: 16px;
    margin-top: 16px;
  }
}
