.home-page-cont {
  padding-right: 7.5vw;
}

.listen-now {
  position: fixed;
  background-color: white;
  font-size: 2.75vw;
  font-weight: 600;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  transform: rotateZ(90deg);
  right: -7.5vw;
  top: 15vw;
  z-index: 99;
}

.listen-now:hover {
  background-color: #59a2c5;
}

.listen-now-link {
  color: black;
  text-decoration: none;
}

.intro-cont {
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-top: 6vw;
}

.intro-about {
  font-size: 2vw;
  font-weight: 400;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 0.3vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 1.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.animation-cont {
  justify-content: center;
}

@keyframes world {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.saveg-world {
  height: 20vw;
  width: 20vw;
  border-radius: 50vw;
  background-color: #59a2c5;
  animation-name: world;
  animation-duration: 45s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes airplane {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2vw);
  }
  100% {
    transform: translateY(0);
  }
}
.saveg-airplane {
  height: 10vw;
  animation-name: airplane;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  padding-bottom: 2vw;
}

.about-hosts-title {
  font-family: Cambria;
  font-size: 4.5vw;
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-top: 5vw;
}

.about-hosts-names {
  position: absolute;
  font-family: Cambria;
  font-size: 6vw;
  font-weight: 100;
  margin-top: 3.5vw;
  margin-left: 20vw;
  text-align: left;
  z-index: 1;
}

.about-hosts-image {
  position: absolute;
  width: 50%;
  right: 0;
  margin-right: 10.5vw;
  margin-top: 5vw;
  z-index: 0;
}

.about-hosts-par {
  position: relative;
  font-size: 1.5vw;
  margin-right: 50%;
  margin-top: 23vw;
  padding: 2.5vw 4vw;
  margin-left: 5vw;
  background-color: white;
  z-index: 1;
}

.about-hosts-par1 {
  text-align: center;
  font-size: 2vw;
  font-weight: bold;
}

.about-hosts-par2 {
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.join-across {
  font-family: Cambria;
  font-size: 4.5vw;
  font-weight: bold;
  text-align: left;
  width: 75%;
  margin-left: 10vw;
  margin-top: 5vw;
  padding-bottom: 2vw;
}

.join-click{
  text-align: left;
  margin-left: 10vw;
  font-size: 3vw;
  padding-bottom: 5vw;
}

.travelled-locations-cont {
  position: absolute;
  width: 20vw;
  height: 40vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  padding-left: 1.2vw;
  font-size: 1.5vw;
  line-height: 3vw;
  background-color: #8fd3af;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 20px;
  left: 6.25vw;
}

.travelled-location-link{
    color: black;
    text-decoration: none;
} 

.travelled-locations-cont div:hover {
  color: white;
  cursor: pointer;
}

.travelled-locations-cont::-webkit-scrollbar {
  width: 25px;
}
.travelled-locations-cont::-webkit-scrollbar-track {
  margin-top: 20px;
  width: 5px;
  margin-bottom: 20px;
}
.travelled-locations-cont::-webkit-scrollbar-thumb {
  background-color: #547865; /* color of the scroll thumb */
  border-radius: 25px; /* roundness of the scroll thumb */
  width: 5px;
  border: 8px #8fd3af solid;
}

.map-cont {
  height: 50vw;
  background-color: #b8e8ce;
}

.leaflet-container {
  position: absolute;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
  border-width: 0px;
  right: 7.5vw;
  width: 60vw;
  height: 40vw;
}

.WorldMap-container a {
  font-size: 1.5vw;
  color: black;
  text-decoration: none;
}

.WorldMap-container a:hover {
  color: red;
}

@media screen and (max-width: 1000px) {
  .home-page-cont {
    padding-right: 0vw;
  }
  .listen-now {
    display: none;
  }
  .saveg-world {
    display: none;
  }
  .saveg-airplane {
    display: none;
  }
  .intro-about {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .about-hosts-image {
    position: relative;
    width: 80vw;
    margin-right: 5vw;
    margin-left: 5vw;
  }
  .about-hosts-names {
    margin-left: 52vw;
    margin-top: 6vw;
  }
  .about-hosts-par {
    margin-left: 15vw;
    margin-top: -15vw;
    margin-right: 5vw;
  }
  .about-hosts-par1 {
    font-size: 2.5vw;
  }
  .about-hosts-par2 {
    font-size: 2vw;
  }
  .travelled-locations-cont{
    display: none;
  }
  .leaflet-container{
    width: 80vw;
    height: 70vw;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .WorldMap-container a {
    font-size: 15px;
  }
  .map-cont {
    height: 80vw;
  }
}

@media screen and (max-width: 750px) {
  .intro-about {
    border-width: 2.25px;
    font-size: 15px;
  }

  .about-hosts-title {
    font-size: 33.75px;
  }

  .join-across {
    font-size: 33.75px;
  }

  .join-click{
    font-size: 22.5px;
    font-weight: 100;
  }

  .about-hosts-par {
    font-weight: 100;
  }

  .about-hosts-par1 {
    font-size: 18.75px;
    font-weight: 400;
  }

  .about-hosts-par2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 750px) and (orientation: landscape) {
  .about-hosts-par1 {
    font-size: 25px;
  }
}
