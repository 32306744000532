.membership-tiers-title{
font-family: Cambria;
font-size: 4.5vw;
font-weight: bold;
margin-bottom: 2vw;
}

.membership-tiers-cont{
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    padding-bottom: 8vw;
    column-gap: 3vw;
}

.saveg-card{
    color: black;
    border-radius: 2vw;
    padding: 3vw;
    width: 40vw;
    margin-top: 0vw;
    cursor: pointer;
}

.saveg-card-fill{
    background-color: #59a2c5;
    color: black;
    border-radius: 2vw;
    padding: 3vw;
    width: 40vw;
    margin-top: 3vw;
}

.saveg-fruit-vegetables{
    margin-left: -4.5vw;
    height: 25vw;
}

.patreon-starter-link{
    color: black;
    text-decoration: none;
    background-color: white;
    margin-top: 3vw;
    border-radius: 2vw;
}

.patreon-starter-link:hover{
    background-color: #59a2c5;
}

.saveg-starter-title{
    font-size: 3vw;
    font-weight: bold;
}

.saveg-starter-price{
    margin-top: 1vw;
    font-size: 3vw;
}

.saveg-starter-benefits-title{
    font-size: 2vw;
    margin-top: 1.33vw;
}

.saveg-starter-benefits{
    width: 33vw;
    margin: 0 auto;
    text-align: left;
    margin-top: 0.5vw;
    font-size: 2vw;
    padding: 0px;
    padding-left: 2vw;
}

.saveg-starter-benefits li{
    padding-top: 0.8vw;
}

.patreon-gang-link{
    background-color: white;
    color: black;
    text-decoration: none;
    margin-top: 3vw;
    border-radius: 2vw;
}

.patreon-gang-link:hover{
    background-color: #59a2c5;
}

.saveg-gang-title{
    font-size: 3vw;
    font-weight: bold;
}

.saveg-gang-price{
    margin-top: 1vw;
    font-size: 3vw;
}

.saveg-gang-benefits-title{
    font-size: 2vw;
    margin-top: 1.33vw;
}

.saveg-gang-benefits{
    width: 33vw;
    margin: 0 auto;
    text-align: left;
    margin-top: 0.5vw;
    font-size: 2vw;
    padding: 0px;
    padding-left: 2vw;
}

.saveg-gang-benefits li{
    padding-top: 0.8vw;
}

.patreon-epic-link{
    background-color: white;
    color: black;
    text-decoration: none;
    margin-top: 3vw;
    border-radius: 2vw;
}

.patreon-epic-link:hover{
    background-color: #59a2c5;
}

.saveg-epic-title{
    font-size: 3vw;
    font-weight: bold;
}

.saveg-epic-price{
    margin-top: 1vw;
    font-size: 3vw;
}

.saveg-epic-benefits-title{
    font-size: 2vw;
    margin-top: 1.5vw;
}

.saveg-epic-benefits{
    width: 33vw;
    margin: 0 auto;
    text-align: left;
    margin-top: 0.5vw;
    font-size: 2vw;
    padding: 0px;
    padding-left: 2vw;
}

.saveg-epic-benefits li{
    padding-top: 0.8vw;
}

@media screen and (min-width: 1200px){
    .membership-tiers-title{
        font-size: 54px;
        margin-bottom: 24px;
        }
    .membership-tiers-cont{
        padding-bottom: 96px;
        column-gap: 36px;
    }
    
    .saveg-card{
        border-radius: 24px;
        padding: 36px;
        width: 480px;
        margin-top: 36px;
    }
    .saveg-card-fill{
        border-radius: 24px;
        padding: 36px;
        width: 480px;
        margin-top: 36px;
    }
    
    .saveg-fruit-vegetables{
        margin-left: -54px;
        height: 300px;
    }
    
    .saveg-starter-title{
        font-size: 36px;
    }
    
    .saveg-starter-price{
        margin-top: 12px;
        font-size: 36px;
    }
    
    .saveg-starter-benefits-title{
        font-size: 24px;
        margin-top: 16px;
    }
    
    .saveg-starter-benefits{    
        width: 396px;
        margin-top: 6px;
        font-size: 24px;
        padding-left: 24px;
    }
    
    .saveg-starter-benefits li{
        padding-top: 9.6px;
    }
    .saveg-gang-title{
        font-size: 36px;
        font-weight: bold;
    }
    
    .saveg-gang-price{
        margin-top: 12px;
        font-size: 36px;
    }
    
    .saveg-gang-benefits-title{
        font-size: 24px;
        margin-top: 16px;
    }
    
    .saveg-gang-benefits{
        width: 396px;
        margin-top: 6px;
        font-size: 24px;
        padding-left: 24px;
    }
    
    .saveg-gang-benefits li{
        padding-top: 9.6px;
    }
    
    .saveg-epic-title{
        font-size: 36px;
    }
    
    .saveg-epic-price{
        margin-top: 12px;
        font-size: 36px;
    }
    
    .saveg-epic-benefits-title{
        font-size: 24px;
        margin-top: 18px;
    }
    
    .saveg-epic-benefits{
        width: 396px;
        margin-top: 6px;
        font-size: 24px;
        padding-left: 24px;
    }
    
    .saveg-epic-benefits li{
        padding-top: 9.6px;
    }
}

@media screen and (min-width: 1500px){
    .membership-tiers-cont{
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
    .saveg-card{
        width: 30vw;
        padding-top: 0px;
    }
    .saveg-card-fill{
        display: none;
    }
    .saveg-starter-benefits{
        width: 25vw;
    }
    .saveg-gang-benefits{
        width: 25vw;
    }
    .saveg-epic-benefits{
        width: 25vw;
    }
}

@media screen and (max-width: 600px){
    .membership-tiers-title{
        margin-bottom: 2vw;
        font-size: 4.5vw;
    }
    .saveg-card{
        width: 70vw;
        margin-top: 0vw;
    }
    .saveg-card-fill{
        display: none;
    }
    .membership-tiers-cont{
        display: grid;
        grid-template-columns: repeat(1, auto);
        row-gap: 3vw;
        padding-bottom: 8vw;
    }
    .saveg-starter-title, .saveg-gang-title, .saveg-epic-title{
        font-size: 4vw;
    }
    .saveg-starter-price, .saveg-gang-price, .saveg-epic-price{
        font-size: 4vw;
    }
    .saveg-starter-benefits-title, .saveg-gang-benefits-title, .saveg-epic-benefits-title{
        font-size: 3vw;
    }
    .saveg-starter-benefits, .saveg-gang-benefits, .saveg-epic-benefits{
        width: 50vw;
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 750px){

    .saveg-card{
        border-radius: 15px;
        margin-top: 0px;
    }
    .membership-tiers-title{
        font-size: 30px;
    }

    .saveg-starter-title, .saveg-gang-title, .saveg-epic-title{
        font-size: 22.5px;
        font-weight: bold;
    }
    
    .saveg-starter-price, .saveg-gang-price, .saveg-epic-price{
        margin-top: 7.5px;
        font-size: 22.5px;
    }
    
    .saveg-starter-benefits-title, .saveg-gang-benefits-title, .saveg-epic-benefits-title{
        font-size: 15px;
        margin-top: 10px;
    }
    
    .saveg-starter-benefits, .saveg-gang-benefits, .saveg-epic-benefits{
        margin-top: 3.75px;
        font-size: 15px;
        padding: 0px;
        padding-left: 15px;
    }
}
    