footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-bottom: 2vw;
  margin-right: 0;
  background-color: white;
  color: black;
  text-align: left;
}
.saveg-logo-footer {
  margin-top: 2vw;
  margin-left: 2vw;
  height: 15vw;
}

.footer-email {
  font-size: 1.5vw;
  margin-top: 1.5vw;
  margin-left: 2vw;
}

footer .copyright {
  font-size: 1vw;
  margin-top: 1.5vw;
  margin-left: 2vw;
}

.web-design-link {
  color: black;
  text-decoration: none;
}

.web-design-link:hover {
  color: #59a2c5;
}

.patreon-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 15vw;
  margin-right: 33vw;
  margin-top: 1.7vw;
}

.youtube-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 12.5vw;
  margin-right: 15vw;
  margin-top: 4vw;
}

.spotify-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 12.5vw;
  margin-right: 34.3vw;
  margin-top: 9.5vw;
}

.soundcloud-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 15vw;
  margin-right: 13.5vw;
  margin-top: 10.5vw;
}

.apple-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 4vw;
  margin-right: 43vw;
  margin-top: 15.5vw;
}

.instagram-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 4vw;
  margin-right: 33vw;
  margin-top: 16.5vw;
}

.facebook-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 4vw;
  margin-right: 23vw;
  margin-top: 16.5vw;
}

.tiktok-logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 4vw;
  margin-right: 13vw;
  margin-top: 16.5vw;
}

@media screen and (max-width: 1200px) {
  footer {
    margin-bottom: 32px;
  }
  .saveg-logo-footer {
    margin-top: 24px;
    margin-left: 24px;
    height: 180px;
  }

  .footer-email {
    font-size: 18px;
    margin-top: 18px;
    margin-left: 24px;
  }

  footer .copyright {
    font-size: 12px;
    margin-right: 15px;
    margin-top: 18px;
    margin-left: 24px;
  }

  .patreon-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 180px;
    margin-right: 33vw;
    margin-top: 20.4px;
  }

  .youtube-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
    margin-right: 15vw;
    margin-top: 48px;
  }

  .spotify-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
    margin-right: 34.3vw;
    margin-top: 114px;
  }

  .soundcloud-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 180px;
    margin-right: 13.5vw;
    margin-top: 126px;
  }

  .apple-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    margin-right: 43vw;
    margin-top: 186px;
  }

  .instagram-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    margin-right: 33vw;
    margin-top: 198px;
  }

  .facebook-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    margin-right: 23vw;
    margin-top: 198px;
  }

  .tiktok-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    margin-right: 13vw;
    margin-top: 198px;
  }
}

@media screen and (max-width: 950px) {
  .patreon-logo {
    width: 18.95vw;
    margin-right: 33vw;
    margin-top: 2vw;
  }

  .youtube-logo {
    width: 15.79vw;
    margin-right: 15vw;
    margin-top: 5.05vw;
  }

  .spotify-logo {
    width: 15.79vw;
    margin-right: 34.3vw;
    margin-top: 12vw;
  }

  .soundcloud-logo {
    width: 18.95vw;
    margin-right: 13.5vw;
    margin-top: 13.26vw;
  }

  .apple-logo {
    width: 5.05vw;
    margin-right: 43vw;
    margin-top: 19.58vw;
  }

  .instagram-logo {
    width: 5.05vw;
    margin-right: 33vw;
    margin-top: 20.84vw;
  }

  .facebook-logo {
    width: 5.05vw;
    margin-right: 23vw;
    margin-top: 20.84vw;
  }

  .tiktok-logo {
    width: 5.05vw;
    margin-right: 13vw;
    margin-top: 20.84vw;
  }
}
@media screen and (max-width: 800px) {
  .patreon-logo {
    display: none;
  }

  .youtube-logo {
    display: none;
  }

  .spotify-logo {
    display: none;
  }

  .soundcloud-logo {
    display: none;
  }

  .apple-logo {
    display: none;
  }

  .instagram-logo {
    display: none;
  }

  .facebook-logo {
    display: none;
  }

  .tiktok-logo {
    display: none;
  }
}

@media screen and (max-width: 514px) {
  .saveg-logo-footer {
    height: 35vw;
  }
}
